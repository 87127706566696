import {addCityCookie} from "../geo/add-city-cookie";

document.addEventListener("DOMContentLoaded", e => {

    document.querySelectorAll('.city-option').forEach(el => el.addEventListener('click', e => {
        e.preventDefault()

        addCityCookie(e.target)
    }))

})
