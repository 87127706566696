import SlimSelect from "slim-select";
import {i18Translate} from "../../utils/i18-init.js";
import Cookies from "js-cookie";
import {DOMAIN} from "../../utils/constant.js";

document.addEventListener("DOMContentLoaded", e => {

    const language = document.getElementById('language'),
        countries = document.getElementById('countries')

    const toolbarLinkLangCountry = document.querySelector('.toolbar-link-lang-country .toolbar-link-lang-country-head')

    if (toolbarLinkLangCountry) {
        toolbarLinkLangCountry.addEventListener('click', () => {
            toolbarLinkLangCountry.closest('.toolbar-link-lang-country').classList.toggle('show')
        })
    }

    if (language) selectLanguage()

    if (countries) selectCountry()

})

const selectLanguage = () => {
    const country = new SlimSelect({
        select: '#language',
        settings: {
            showSearch: true,
            placeholderText: i18Translate('delivery-js.select-country'),
            searchText: i18Translate('delivery-js.nothing-was-found'),
            searchPlaceholder: i18Translate('delivery-js.search'),
        },
        events: {
            searchFilter: (option, search) => {
                return option.data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
            },
            beforeChange: (newVal, oldVal) => {
                const pathname = replaceLanguageCode(window.location.pathname, newVal[0].data.languageCode)

                window.location.href = `${window.location.origin}${pathname}`
                //window.location.href = `${window.location.origin}/locale/${newVal[0].data.languageCode}`
            }
        }
    })
}

const selectCountry = () => {
    const country = new SlimSelect({
        select: '#countries',
        settings: {
            showSearch: true,
            placeholderText: i18Translate('delivery-js.select-country'),
            searchText: i18Translate('delivery-js.nothing-was-found'),
            searchPlaceholder: i18Translate('delivery-js.search'),
        },
        events: {
            searchFilter: (option, search) => {
                return option.data.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
            },
            beforeChange: (newVal, oldVal) => {
                console.log(DOMAIN)
                const data = newVal[0].data,
                    country = Cookies.get('hitno_country')

                if (country && Number(country) !== Number(data.id)) {
                    Cookies.remove('hitno_city')
                }

                if (country) {
                    Cookies.remove('hitno_country')
                }

                Cookies.set('hitno_country', data.id, {
                    expires: 7,
                    domain: (DOMAIN === 'hitno.local') ? DOMAIN : 'hitno.com',
                    path: '/'
                })

                location.reload()
            }
        }
    })
}

export const replaceLanguageCode = (str, languageCode) => {
    const code = (languageCode === 'pt') ? 'pt-br' : languageCode,
        segments = str.split('/')

    const languageCodes = ['fr', 'sr', 'es', 'tr', 'pt-br', 'de', 'it', 'nl', 'pl'],
        isLanguageSegment = (segment) => languageCodes.includes(segment)

    const hasLanguage = segments.some(segment => isLanguageSegment(segment))

    if (!hasLanguage) {
        return `/${code}${str}`
    }

    if (languageCode === 'en') {
        return segments.filter(segment => !isLanguageSegment(segment)).join('/')
    }

    return segments.map(segment => isLanguageSegment(segment) ? code : segment).join('/')
}






















