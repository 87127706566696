import {initSwiper} from "./init-swiper";

document.addEventListener("DOMContentLoaded", e => {
    
    if (!document.querySelectorAll('.swiper-previews').length) {
        return false
    }

    initSwiper()
})

