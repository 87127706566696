import {postLogout} from "../services/authorization-services";
import {getPrefixLang} from "../utils/getPrefixLang.js";

document.addEventListener("DOMContentLoaded", e => {

    const logout = document.querySelectorAll('#logout')

    if (logout.length === 0) return false

    logout.forEach(el => el.addEventListener('click', e => {
        e.preventDefault()

        postLogout().then(e => {
            const lang = getPrefixLang()

            window.location.href = lang ? `/${lang}/` : `/`
        })
    }))

})
