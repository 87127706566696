import {i18Translate} from "../../utils/i18-init";
import {searchAd, searchProduct} from "../../services/search-services";
import Cookies from "js-cookie";
import {DOMAIN} from "../../utils/constant";
import axios from "axios";
import {getPrefixLang} from "../../utils/getPrefixLang.js";

document.addEventListener("DOMContentLoaded", e => {

    const ajaxLiveSearch = document.querySelector('.ajax-live-search')

    if (!ajaxLiveSearch) return null

    const headerWowStickySearch = (document.querySelector('.header-wow-mobile-sticky-search'))
        ? document.querySelector('.header-wow-mobile-sticky-search')
        : document.querySelector('.header-wow-sticky-search')

    const stickySearchControlFull = document.querySelector('.sticky-search-control-full'),
        headerWowStickySearchIcon = document.querySelector('.header-wow-sticky-search-icon')

    let currentAbortController = null

    ajaxLiveSearch.addEventListener('input', e => {

        const value = ajaxLiveSearch.value,
            isSale = Boolean(ajaxLiveSearch.getAttribute('data-is-sale'))

        if (value.length <= 1) {
            if (headerWowStickySearch.classList.contains('searching')) {
                headerWowStickySearch.classList.remove('searching')
            }
            return false
        }

        headerWowStickySearch.classList.add('searching')

        /**
         * Includes text please wait
         */
        document.querySelector('.sticky-search-ad-list').innerHTML = `<span class="item-city">${i18Translate('constant.search')}</span>`

        if (currentAbortController) {
            currentAbortController.cancel()
        }

        currentAbortController = axios.CancelToken.source()

        if (isSale) {
            searchProduct({
                search: value,
                countryId: Cookies.get('hitno_country'),
                signal: currentAbortController.token
            }).then(e => insertResultSearch(e.data.data))
        } else {
            searchAd({
                search: value,
                countryId: Cookies.get('hitno_country'),
                isSale
            }).then(e => insertResultSearch(e.data.data))
        }
    })

    /**
     *
     */
    if (stickySearchControlFull) {
        stickySearchControlFull.addEventListener('click', e => openPageFullSearch({
            value: ajaxLiveSearch.value,
            isSale: ajaxLiveSearch.getAttribute('data-is-sale')
        }))
    }
    if (headerWowStickySearchIcon) {
        headerWowStickySearchIcon.addEventListener('click', e => openPageFullSearch({
            value: ajaxLiveSearch.value,
            isSale: ajaxLiveSearch.getAttribute('data-is-sale')
        }))
    }


})

const insertResultSearch = (result) => {

    const ajaxLiveSearch = document.querySelector('.ajax-live-search'),
        isSale = Boolean(ajaxLiveSearch.getAttribute('data-is-sale'))

    const stickySearchAd = document.querySelector('.sticky-search-ad-list')

    if (
        !result.posts.length &&
        !result.category.length &&
        !result.queries.length
    ) {
        stickySearchAd.innerHTML = `<span class="item-city">${i18Translate('constant.there-is-nothing')}</span>`
        return false
    }

    stickySearchAd.innerHTML = ``

    // Queries
    let queries = ``

    for (let i = 0; result.queries.length > i; i++) {
        const query = result.queries[i]
        queries += `<a href=${query.url} target="_blank"><div>${query.name}</div></a>`
    }

    // Category
    let category = ``

    for (let i = 0; result.category.length > i; i++) {
        const cat = result.category[i]
        category += `<a href=${cat.url} target="_blank"><div>${cat.name}</div></a>`
    }

    // Posts
    let posts = ``

    for (let i = 0; result.posts.length > i; i++) {
        const post = result.posts[i]
        posts += `
                <a href=${post.url} target="_blank">
                    ${(!isSale) ? post.icon : `<img src="${post.image}" alt="${post.title}" />`}
                    <div>
                        ${post.title}
                        <span>${(post.address) ? `${post.category} • ${post.address}` : post.category}</span>
                    </div>
                </a>
            `
    }

    stickySearchAd.innerHTML = `
        ${
        (queries.length)
            ? `<div class="search-category"><span class="search-title">${i18Translate('header-catalogy-mobile.quick-search')}</span>${queries}</div>`
            : ``
    }
        ${
        (category.length)
            ? `<div class="search-category"><span class="search-title">${i18Translate('header-catalogy-mobile.categories')}</span>${category}</div>`
            : ``
    }
        ${
        (posts.length)
            ? `<div class="search-announcements">${posts}</div>`
            : ``
    }
    `
}

const openPageFullSearch = ({value, isSale}) => {
    if (value.length <= 1) return false

    const lang = getPrefixLang(),
        prefixSearch = isSale ? 'search' : 'pretraga'

    window.location.href = lang
        ? `${window.location.protocol}//${DOMAIN}/${lang}/${prefixSearch}=${value}`
        : `${window.location.protocol}//${DOMAIN}/${prefixSearch}=${value}`
}
























