document.addEventListener("DOMContentLoaded", e => {

    const headerFlexBox = document.getElementById('header-flex-box-2'),
        headerBigMenu = document.getElementById('header-big-menu'),
        headerButtonMenuCatalog = document.getElementById('header-button-menu-catalog'),
        headerWowMobileStickySearch = document.querySelector('.header-wow-mobile-sticky-search'),
        headerWowStickySearch = document.querySelector('.header-wow-sticky-search'),
        toolbarDropdownBox = document.querySelector('.toolbar-dropdown')

    const toolbarLinkLangCountry = document.querySelector('.toolbar-link-lang-country')

    // const countries = document.querySelector('.countries'),
    //     languages = document.querySelector('.languages')

    document.body.addEventListener('click', e => {

        closingWindowGeo({event: e, FlexBox: headerFlexBox, BigMenu: headerBigMenu})
        // closingWindowCountry({event: e, countries})
        // closingWindowLanguages({event: e, languages})
        closingWindowMenu({event: e, BigMenu: headerBigMenu})
        closingWindowMobileSearch({event: e, element: headerWowMobileStickySearch})
        closingWindowSearch({event: e, element: headerWowStickySearch})
        closingLangAndCountry({event: e, element: toolbarLinkLangCountry})
        closingWindowListMenu({event: e, element: toolbarDropdownBox})

    })

})

const closingWindowListMenu = ({event, element}) => {
    if (
        !event.target.closest('.toolbar-dropdown') &&
        element &&
        element.classList.contains('show')
    ) {
        element.classList.remove('show')
    }
}

const closingLangAndCountry = ({event, element}) => {
    if (
        !event.target.closest('.toolbar-link-lang-country') &&
        element &&
        element.classList.contains('show')
    ) {
        element.classList.remove('show')
    }
}

const closingWindowSearch = ({event, element}) => {
    if (
        !event.target.closest('.header-wow-sticky-search') &&
        element &&
        element.classList.contains('searching')
    ) {
        element.classList.remove('searching')
    }
}

const closingWindowMobileSearch = ({event, element}) => {
    if (

        !event.target.closest('.header-wow-mobile-sticky-search') &&
        element &&
        element.classList.contains('searching')

    ) {
        element.classList.remove('searching')
    }
}

const closingWindowMenu = ({event, BigMenu}) => {
    if (
        !event.target.closest('.header-big-menu') &&
        !event.target.closest('.header-button-menu-catalog') &&
        BigMenu &&
        BigMenu.classList.contains('show')
    ) {
        BigMenu.classList.remove('show')
    }
}

// const closingWindowLanguages = ({event, languages}) => {
//     if (
//         !event.target.closest('.languages') &&
//         languages &&
//         languages.classList.contains('show')
//     ) {
//         languages.classList.remove('show')
//     }
// }

// const closingWindowCountry = ({event, countries}) => {
//     if (
//         !event.target.closest('.countries') &&
//         countries &&
//         countries.classList.contains('show')
//     ) {
//         countries.classList.remove('show')
//     }
// }

const closingWindowGeo = ({event, FlexBox, BigMenu}) => {
    if (
        !event.target.closest('.header-wow-sticky-search') &&
        !event.target.closest('.sticky-search-control-geo') &&
        FlexBox &&
        FlexBox.classList.contains('search-show')
    ) {
        FlexBox.classList.remove('search-show')
    }

    if (
        !event.target.closest('.header-big-menu') &&
        !event.target.closest('.header-button-menu-catalog') &&
        !event.target.classList.contains('header-big-sub-open') &&
        BigMenu &&
        BigMenu.classList.contains('show')
    ) {
        BigMenu.classList.remove('show')
        document.getElementById('header-button-menu-catalog').classList.remove('active')
    }
}
